import axios from "../axios";
export default {
  login(data) {
    return axios({
      // url: `https://developer.cloudervr.com/chongqingpsyport/login.php`,
      url: `/users/login?username=${data.username}&password=${data.password}`,
      method: "post",
      data,
    });
  },
  // 修改密码
  update(data) {
    return axios({
      url: `/users/update?username=${data.username}&password=${data.password}&n_password=${data.n_password}`,
      method: "post",
      data,
    });
  },

  // 用于评估验证患者的登录
  PatientLogin(params) {
    return axios({
      url: `/ExamSolution/PatientLogin`,
      method: "get",
      params,
    });
  },

  // 用户注册
  Register(data) {
    return axios({
      url: `/users/register?username=${data.name}&password=${data.pass}`,
      method:'post',
      data
    });
  },
};
