import api from '../../http/api'
import router from 'vue-router'
import {
    Message
} from "element-ui";
// import login from '../../http/modules/login'

export default {
    // 解决命名冲突
    namespaced: true,
    state: {
        user: {
            username: "admin",
            password: "admin123"
        },
        userData: {}

    },
    mutations: {

    },
    actions: {
      
    }
}
