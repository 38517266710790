import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import ElementUI from "element-ui";
// import vant from "vant";  //引入vant
import locale from "element-ui/lib/locale/lang/zh-CN";
import "./styles.scss";
import VueQriously from "vue-qriously"; //生成二维码
// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.use(VueQriously, echarts);

// import http from './http/axios'

Vue.use(ElementUI, {
  locale,
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
