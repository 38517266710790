import api from "../../http/api";
import { Message } from "element-ui";
export default {
    // 解决命名冲突
    namespaced: true,
    state: {
        data: [],
        delList: [],
        total: 1,
        total2: 1,
        pages: 1,
        cur: 0,
        size: 1,
        rport: {
            from: '',
            to: '',
            findKey: ''
        },
        AssessmentReport: {
            from: '',
            to: '',
            findKey: ''
        },
        searchData: {
            cur: 1,
            size: 10,
        },
        searchData2: {
            cur: 1,
            size: 10,
        },
        VRRport: [],
        AssessmentData: []
    },
    mutations: {
        setVRReport(state, val) {
            state.VRRport = val;
            // console.log("VR训练报告", state.VRRport);
        },
        setTotal(state, val) {
            state.total = val;
            // console.log("total：", state.total);
        },
        setTotal2(state, val) {
            state.total2 = val;
        },
        setData(state, val) {
            state.data = val;
            // console.log("患者列表", state.data);
        },
        // 获取已删除患者列表
        setDelData(state, val) {
            state.delList = val;
            // console.log("患者列表", state.delList);
        },

        setRport(state, val) {
            state.rport = val;
            // console.log("报告列表", state.rport);
        },
        setAssessmentReport(state, val) {
            state.AssessmentData = val
        },

        changeSearchData(state, val) {
            state.searchData.value = val;
        },
        changeSearchData2(state, val) {
            state.searchData2.value = val;
        },
        changePageSize(state, val) {
            state.searchData.size = val;
        },
        changePageSize2(state, val) {
            state.searchData2.size = val;
        },
        changeCurrentpage(state, val) {
            state.searchData.cur = val;
        },
        changeCurrentpage2(state, val) {
            state.searchData2.cur = val;
        },
        // 下一页
        nextPage(state) {
            if (state.cur < state.pages) {
                state.searchData.cur++;
            }
        },
        nextPage2(state) {
            if (state.cur < state.pages) {
                state.searchData2.cur++;
            }
        },
        // // 点击下拉框
        Selects(state, val) {
            state.searchData.size = val;
        },
        //   输入页面跳转
        jumper(state, val) {
            state.searchData.cur = val
        },
        //   输入页面跳转
        jumper2(state, val) {
            state.searchData2.cur = val
        },
        Selects2(state, val) {
            state.searchData2.size = val;
        },
        // 首页
        firstPage(state) {
            if ((state.searchData.cur = 1));
        },
        firstPage2(state) {
            if ((state.searchData2.cur = 1));
        },
        // 页尾
        lastPage(state) {
            if ((state.searchData.cur = state.searchData.pages));
        },
        lastPage2(state) {
            if ((state.searchData2.cur = state.searchData2.pages));
        },
        // 上一页
        prevPage(state) {
            if (state.searchData.cur > 1) {
                state.searchData.cur--;
            }
        },
        prevPage2(state) {
            if (state.searchData2.cur > 1) {
                state.searchData2.cur--;
            }
        },
    },
    actions: {
        // 获取患者数据
        async getPatient(context) {
            const data = await api.patient.getPatient(context.state.searchData);
            context.commit("setData", data.data.data.records);
            context.commit("setTotal", data.data.data.total);
        },
        // 获取已删除患者数据
        async getDelList(context) {
            const data = await api.patient.DelList(context.state.searchData2);
            context.commit("setDelData", data.data.data.records);
            context.commit("setTotal2", data.data.data.total);
        },

        // 新增患者
        async addPatient(context, list) {
            const { data } = await api.patient.addPatient({
                list,
            });

            if (data) {
                context.dispatch("getPatient");
            }
        },

        // 患者详情
        async getDetails(context, ID) {
            const { data } = await api.patient.getDetails(ID);
            context.commit("setDetails", data);
        },
        // 永久删除患者信息
        async remove(context, _id) {
            const data = await api.patient.remove({ _id });
            if (data) {
                context.dispatch("getDelList");
            }
        },
        // 删除患者（软删除）
        async delOne(context, _id) {
            const { data } = await api.patient.delOne({ _id });
            if (data) {
                context.dispatch("getPatient");
            }
        },

        // 恢复患者
        async recovery(context, cid) {
            const { data } = await api.patient.recovery({ cid });
            if (data) {
                context.dispatch("getDelList");
                context.dispatch("getPatient");
            }
        },

        //  确定修改
        async updateCal(context, update) {
            const data = await api.patient.updateCal({...update });
            if (data) {
                context.dispatch("getPatient");
                // console.log(1111111, data);
            }
        },
        // 评估报告列表
        async lookRport(context) {
            const { data } = await api.patient.lookRport(context.state.AssessmentReport);
            context.commit("setAssessmentReport", data.data);
            // console.log("报告列表", data);
        },

        // 修改编辑报告
        async updateReport(context, update2) {
            const data = await api.patient.updateReport({...update2 });
            if (data) {
                context.dispatch("getPatient");
                // console.log("修改报告", data);
            }
        },
        // 删除评估报告
        async delRport(context, _id) {
            const data = await api.patient.delRport({ _id });
            if (data) {
                context.dispatch("lookRport");
            }
        },

        // 拉取VR训练报告
        async getReoprtALl(context) {
            const data = await api.patient.getReoprtALl(context.state.rport);
            context.commit("setVRReport", data.data.data);
            // console.log("setVRReport", data);
        },

        // 删除对应VR报告
        async delVRone(context, _id) {
            const data = await api.patient.delVRone({ _id });
            if (data) {
                context.dispatch("getReoprtALl");
            }
            // console.log(2323232, data);
        },
    },
};