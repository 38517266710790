import axios from "axios";
export default {
  // 获取VR方案数据
  getAllsubject(params) {
    return axios({
      url: "http://localhost:3000/MEQ/getAllsubject",
      method: "get",
      params,
    });
  },
}
