import api from "../../http/api";
export default {
  namespaced: true,
  state: {
    data: [],
    option: [],
  },
  mutations: {
    setData(state, val) {
      state.data = val;
      // console.log("MEQ答题数据", state.data);
    },
    setOption(state, val) {
      state.option = val;
      // console.log("MEQ选项数据", state.option);
    },
  },
  actions: {
    async getAllsubject(context) {
      const { data } = await api.MEQ.getAllsubject(context.state.data);
      context.commit("setData", data.data);
      // console.log("获取MEQ答题数据", data);
    },

    async getAllOption(context) {
      const opData = await api.MEQ.getAllsubject(context.state.option);
      context.commit("setOption", opData.data.data[18].option);
      // console.log("获取MEQ选项数据", opData);
    },
  },
};
