import patient from "./modules/patient.js";
import login from "./modules/login";
import VRPlan from "./modules/VRPlan";
import MEQ from "./modules/MEQ";
export default {
  patient,
  login,
  VRPlan,
  MEQ,
};
