<template>
   <div class="block">
      <el-pagination @size-change="handleSizeChange"
                     :current-page="searchData.cur"
                     :page-sizes="[5, 10, 20, 30]"
                     :page-size="searchData.size"
                     @prev-click="prevPages"
                     @next-click="nextPages"
                     @current-change='jumpers'
                     layout="total,sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
   </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
   "patient"
);
export default {
   created () {
      // this.Selects();
      // this.getPatient();
   },

   methods: {
      ...mapMutations([
         "jumper",
         "nextPage",
         "firstPage",
         "lastPage",
         "prevPage",
         "Selects",
         "changeSearchData",
         "changePageSize",
         "changeCurrentpage",
      ]),

      ...mapActions(["getPatient"]),
      //  点击页码跳转或输入页面跳转
      jumpers (val) {
         this.jumper(val);
         this.getPatient()
      },
      firstPages () {
         this.firstPage();
         this.getPatient();
      },
      nextPages () {
         this.nextPage();
         this.getPatient();
      },
      lastPages () {
         this.lastPage();
         this.getPatient();
      },
      prevPages () {
         this.prevPage();
         this.getPatient();
      },
      // 下拉框 选择显示条数
      handleSizeChange (val) {
         this.Selects(val);
         this.getPatient();
      },
   },
   computed: {
      ...mapState(["total", "pages", "searchData", "data"]),
   },
   data () {
      return {
         selects: {
            Pages: 0,
            cur: 1,
         },
      };
   },
};
</script>

<style>
.block {
   width: 100%;
   margin-top: 20px;
   text-align: center;
}
</style>
