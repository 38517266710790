// 对原生 axios 进行封装
import axios from "axios";
const myAxios = axios.create({
    // axios 的 url 的基础路径
    baseURL: "http://139.155.206.45:3100/",
    //  baseURL: "http://localhost:3100/",
    // 用于请求超时的时间，超过timeout的时间后，会直接报错
    timeout: 3000,
});

// axios 请求拦截器
myAxios.interceptors.request.use(
    (config) => {
        // 拦截成功时执行该方法
        // 给请求头添加 token
        const token = localStorage.token;
        config.headers.Authorization = "Bearer " + token;
        // console.log("config", config);
        return config;
    },
    (err) => {
        // 拦截失败时执行该方法
        return err;
    }
);

// axios 响应拦截器
myAxios.interceptors.response.use(
    (res) => {
        // 响应成功执行该方法
        return res;
    },
    (err) => {
        // 响应失败执行该方法
        let message = "服务器错误";
        if (err && err.response) {
            switch (err.response.status) {
                case 401:
                    message = "身份认证失败(401)";
                    // alert("未登录，请先登录");
                    // $router.push("/login");
                    break;
                case 404:
                    message = "请求资源找不到(404)";
                    break;
                case 500:
                    message = "服务器错误(500)";
                    break;
                case 504:
                    message = "网络超时(504)";
                    break;
            }
            return {
                data: {
                    message,
                    type: err.response.status,
                    status: 0,
                },
            };
        } else {
            return {
                data: {
                    message: "服务器连接失败！",
                    status: 0,
                },
            };
        }
    }
);

export default myAxios;