<template>
   <div>
      <div>
         <el-table :data="data"
                   border
                   style="width: 100%">
            <el-table-column prop="cname"
                             label="患者姓名"
                             align="center"
                             width="150">
            </el-table-column>

            <el-table-column prop="sex"
                             label="性别"
                             align="center"
                             width="150">
            </el-table-column>
            <el-table-column prop="age"
                             label="年龄"
                             align="center"
                             width="150"></el-table-column>
            <el-table-column label="联系电话"
                             align="center"
                             width="220">
               <template slot-scope="scope">{{
            scope.row.phone | hideMiddle
          }}</template>
            </el-table-column>
            <el-table-column label="身份证号"
                             align="center"
                             width="220"><template slot-scope="scope">{{
            scope.row.cid | hideMiddle
          }}</template>
            </el-table-column>
            <el-table-column prop="history"
                             label="既往病史"
                             align="center"
                             width="150">
            </el-table-column>
            <el-table-column label="就诊卡号"
                             align="center"
                             width="150"><template slot-scope="scope">{{
            scope.row.hospitalId | hideMiddle
          }}</template></el-table-column>
            <el-table-column prop="address"
                             label="地址"
                             align="center"
                             width="150">
            </el-table-column>
            <el-table-column fixed="right"
                             label="操作"
                             width="300"
                             align="center">
               <template slot-scope="scope">
                  <el-button type="primary"
                             icon="el-icon-view"
                             size="mini"
                             @click="gotoEchats(scope.row.cid)">查看</el-button>
                  <el-button type="success"
                             icon="el-icon-edit"
                             size="mini"
                             @click="getPatientOne(scope.row.cid)">编辑</el-button>
                  <el-button @click="delPatient(scope.row.id)"
                             size="mini"
                             type="danger"
                             icon="el-icon-circle-close">删除</el-button>
               </template>
            </el-table-column>
         </el-table>
      </div>
      <!-- 弹出框修改form-->
      <el-dialog title="修改患者信息"
                 :visible.sync="dialogVisible"
                 width="30%"
                 :before-close="handleClose">
         <span>
            <el-form ref="form"
                     :model="update"
                     label-width="80px"
                     size="mini">
               <el-form-item label="患者姓名">
                  <el-input v-model="update.cname"></el-input>
               </el-form-item>
               <el-form-item label="患者年龄">
                  <el-input v-model="update.age"></el-input>
               </el-form-item>
               <el-form-item label="身份证">
                  <el-input v-model="update.cid"></el-input>
               </el-form-item>
               <el-form-item label="就诊卡号">
                  <el-input v-model="update.hospitalId"></el-input>
               </el-form-item>
               <el-form-item label="患者性别">
                  <el-select v-model="update.sex">
                     <el-option label="男"
                                value="男">男</el-option>
                     <el-option label="女"
                                value="女">女</el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="联系电话">
                  <el-input v-model="update.phone"></el-input>
               </el-form-item>
               <el-form-item label="既往病史">
                  <el-input v-model="update.history"></el-input>
               </el-form-item>
               <el-form-item label="患者地址">
                  <el-input v-model="update.address"></el-input>
               </el-form-item>
            </el-form>
         </span>
         <el-button @click="noUpdate">取 消</el-button>
         <el-button type="primary"
                    @click="updateBtn">确 定</el-button>
      </el-dialog>

      <Page></Page>
   </div>
</template>
<script>
import { Message } from "element-ui";
import Page from "./Page.vue";
import { createNamespacedHelpers } from "vuex";
// import axios from "../http/axios";
import api from "../http/api";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
   "patient"
);
export default {
   components: {
      Page,
   },
   created () {
      this.getPatient();
   },

   filters: {
      hideMiddle (val) {
         return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
      },
   },
   computed: {
      ...mapState(["data", "updates", "pageSize", "currentpage"]),
   },
   methods: {
      ...mapMutations([
         "changePageData",
         "changeSearchData",
         "changePageSize",
         "changeCurrentpage",
      ]),
      ...mapActions(["getPatient", "updateCal", "delOne", "updateReport"]),

      //   点击修改的弹出框方法
      handleClose (done) {
         this.$confirm("确认关闭？")
            .then((_) => {
               done();
            })
            .catch((_) => { });
      },
      // 进入对应患者的可视化数据页面
      gotoEchats (cid) {
         //  this.$router.push(`/Correspondence_Report/${cname}/${id}`);
         this.$router.push(`/system/lookEchart/${cid}`);
      },
      // 获取某一个患者数据
      async getPatientOne (cid) {
         this.dialogVisible = true;
         const data = await api.patient.search(cid);
         if (data.status == 200) {
            this.update = data.data.data.records[0];
            // console.log("获取某一个数据：", this.update);
         }
      },
      // 确定修改
      updateBtn () {
         this.updateCal(this.update);
         Message.success("修改成功");
         this.getPatient();
         this.dialogVisible = false;
      },
      noUpdate () {
         this.dialogVisible = false;
         Message.info("已取消修改");
      },
      // 删除患者
      delPatient (id) {
         // console.log("id", id);
         this.getPatient();
         // 删除提示框
         this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "danger",
         })
            .then(() => {
               this.$message({
                  type: "success",
                  message: "删除成功！",
               });

               this.delOne(id);
            })
            .catch(() => {
               this.$message({
                  type: "info",
                  message: "已取消删除",
               });
            });
      },
   },

   data () {
      return {
         dialogVisible: false,
         dialogVisible2: false,
         searchData: [],
         findKey: "",
         // handleClose: false,
         input: "",
         name: "",
         // 存放修改患者数据
         update: {

         },
         // 存放修改报告数据
         update2: {

         },
      };
   },
};
</script>
<style lang="scss" scoped>
.item {
   margin: 10px;
}
.cell {
   text-align: center;
}
#searcheBox {
   width: 200px;
}
.el-input {
   width: 200px;
}
</style>
