import api from "../../http/api";
export default {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    setVRPlan(state, val) {
      state.data = val;
      // console.log("VR方案列表11111", state.data);
    },
  },
  actions: {
    // 获取VR方案数据
    async getAllVRPlan(context) {
      const { data } = await api.VRPlan.getAllVRPlan(context.state.data);
      context.commit("setVRPlan", data.data);

      // console.log("获取VR方案数据", data);
    },
    // 新增
    async addVRPlan(context, list) {
      const data = await api.VRPlan.addVRPlan({
        list,
      });
      if (data) {
        context.dispatch("getAllVRPlan");
        // console.log("新增方案", data);
      }
    },
    // 删除
    async delVRPlan(context, _id) {
      const data = await api.VRPlan.delVRPlan({ _id });
      if (data) {
        context.dispatch("getAllVRPlan");
      }
    },
    // 确定修改
    async updateCal(context, LookOne) {
      const data = await api.VRPlan.updateCal({ ...LookOne });
      if (data) {
        context.dispatch("getAllVRPlan");
      }
    },
  },
};
